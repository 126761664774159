import React, { useState, useEffect } from "react"

import {
  StyledSidebarLink,
  CoreAppLink,
} from "./Styles/Sidebar/SidebarLinkStyles"

export const SidebarLink = ({ icon, label, href }) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (
      (href != undefined && href == window.location.href) ||
      href == window.location.pathname
    ) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [href])

  const handleClick = () => {
    setIsActive(!isActive)
  }

  return (
    <StyledSidebarLink onClick={handleClick}>
      {href && (
        <CoreAppLink className={isActive ? "active" : null} href={href}>
          {icon}
          <span>{label}</span>
        </CoreAppLink>
      )}
    </StyledSidebarLink>
  )
}

export default SidebarLink
