require("@rails/ujs").start()
require("react")
// Support component names relative to this directory:

var componentRequireContext = require.context(".", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

ReactRailsUJS._firstTurboLoadSkipped = false
ReactRailsUJS.handleEvent("turbo:load", () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount()
  ReactRailsUJS._firstTurboLoadSkipped = true
})

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent("turbo:before-render", (e) => {
  ReactRailsUJS.unmountComponents(e.target)
})
